import React from 'react'
import Layout from '../components/layout'

function PrivacyPolicy () {
  return (
    <Layout>
      <div className="w-full max-w-[800px] mx-auto mt-[144px] lg:mt-[240px] pb-32">
        <div>
          <div className="text-center">
            <div className="">LegalEye</div>
            <h1 className="text-3xl mt-6 md:text-6xl  md:leading-tight font-GTWalsheimPro font-medium">
              Privacy Policy
            </h1>
          </div>
          <p className="mt-8 text-[#6a6a6a]">
            This privacy policy applies between you, the User of this Website
            and LawAdvisor Ventures Ltd, the owner and provider of this Website.
            LegalEye takes the privacy of your information very seriously. This
            privacy policy applies to our use of any and all Data collected by
            us or provided by you in relation to your use of the Website.
            <br />
            <b>Please read this privacy policy carefully.</b>
          </p>
          <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
            Definitions and interpretation
          </h3>
          <ol className="list-decimal list-inside">
            <li className="mt-4">
              In this privacy policy, the following definitions are used:
            </li>
            <table>
              <tr className="border-b">
                <td className="p-2 pl-0">Data</td>
                <td className="p-2 pr-0">
                  collectively all information that you submit to LegalEye via
                  the Website. This definition incorporates, where applicable,
                  the definitions provided in the Data Protection Laws;
                </td>
              </tr>
              <tr className="border-b">
                <td className="p-2 pl-0">Cookies</td>
                <td className="p-2 pr-0">
                  a small text file placed on your computer by this Website when
                  you visit certain parts of the Website and/or when you use
                  certain features of the Website. Details of the cookies used
                  by this Website are set out in the clause below (
                  <b>Cookies</b>
                  );
                </td>
              </tr>
              <tr className="border-b">
                <td className="p-2 pl-0">Data Protection Laws</td>
                <td className="p-2 pr-0">
                  any applicable law relating to the processing of personal
                  Data, including but not limited to the Directive 96/46/EC
                  (Data Protection Directive) or the GDPR, and any national
                  implementing laws, regulations and secondary legislation, for
                  as long as the GDPR is effective in the UK;
                </td>
              </tr>
              <tr className="border-b">
                <td className="p-2 pl-0">GDPR</td>
                <td className="p-2 pr-0">
                  the General Data Protection Regulation (EU) 2016/679;
                </td>
              </tr>
              <tr className="border-b">
                <td className="p-2 pl-0">LegalEye, we or us</td>
                <td className="p-2 pr-0">
                  LegalEye, a company incorporated in England and Wales with
                  registered number 12365236 whose registered office is at
                  Suite 2008 Unit 3a, 34-35 Hatton Garden, London, England,
                  EC1N 8DX;
                </td>
              </tr>
              <tr className="border-b">
                <td className="p-2 pl-0">UK and EU Cookie Law</td>
                <td className="p-2 pr-0">
                  the Privacy and Electronic Communications (EC Directive)
                  Regulations 2003 as amended by the Privacy and Electronic
                  Communications (EC Directive) (Amendment) Regulations 2011;
                </td>
              </tr>
              <tr className="border-b">
                <td className="p-2 pl-0">User or you</td>
                <td className="p-2 pr-0">
                  any third party that accesses the Website and is not either
                  (i) employed by LegalEye and acting in the course of their
                  employment or (ii) engaged as a consultant or otherwise
                  providing services to LegalEye and accessing the Website in
                  connection with the provision of such services; and
                </td>
              </tr>
              <tr className="border-b">
                <td className="p-2 pl-0">Website</td>
                <td className="p-2 pr-0">
                  the website that you are currently using, www.legaleye.com,
                  and any sub-domains of this site unless expressly excluded by
                  their own terms and conditions.
                </td>
              </tr>
            </table>
            <li className="mt-4">
              In this privacy policy, unless the context requires a different
              interpretation:
            </li>
            <ol className="list-alpha list-inside ml-4">
              <li className="mt-4">
                the singular includes the plural and vice versa;
              </li>
              <li className="mt-4">
                references to sub-clauses, clauses, schedules or appendices are
                to sub-clauses, clauses, schedules or appendices of this privacy
                policy;
              </li>
              <li className="mt-4">
                a reference to a person includes firms, companies, government
                entities, trusts and partnerships;
              </li>
              <li className="mt-4">
                &quot;including&quot; is understood to mean &quot;including
                without limitation&quot;;
              </li>
              <li className="mt-4">
                reference to any statutory provision includes any modification
                or amendment of it;
              </li>
              <li className="mt-4">
                the headings and sub-headings do not form part of this privacy
                policy.
              </li>
            </ol>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Scope of this privacy policy
            </h3>
            <li className="mt-4">
              This privacy policy applies only to the actions of LegalEye and
              Users with respect to this Website. It does not extend to any
              websites that can be accessed from this Website including, but not
              limited to, any links we may provide to social media websites.
            </li>
            <li className="mt-4">
              For purposes of the applicable Data Protection Laws, LegalEye is
              the &quot;data controller&quot;. This means that LegalEye
              determines the purposes for which, and the manner in which, your
              Data is processed.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Data collected
            </h3>
            <li className="mt-4">
              We may collect the following Data, which includes personal Data,
              from you:
              <ol className="list-alpha list-inside">
                <li className="mt-4">name;</li>
                <li className="mt-4">job title;</li>
                <li className="mt-4">profession;</li>
                <li className="mt-4">
                  contact Information such as email addresses and telephone
                  numbers;
                </li>
                <li className="mt-4">IP address (automatically collected);</li>
                <li className="mt-4">
                  web browser type and version (automatically collected);
                </li>
                <li className="mt-4">
                  operating system (automatically collected);
                </li>
              </ol>
            </li>
            in each case, in accordance with this privacy policy.
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              How we collect Data
            </h3>
            <li className="mt-4">
              We collect Data in the following ways:
              <ol className="list-alpha list-inside ml-4">
                <li className="mt-4">data is given to us by you; and</li>
                <li className="mt-4">data is collected automatically.</li>
              </ol>
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Data that is given to us by you
            </h3>
            <li className="mt-4">
              LegalEye will collect your Data in a number of ways, for example:
              <ol className="list-alpha list-inside ml-4">
                <li className="mt-4">
                  when you contact us through the Website, by telephone, post,
                  e-mail or through any other means;
                </li>
                <li className="mt-4">
                  when you register with us and set up an account to receive our
                  products/services;
                </li>
                <li className="mt-4">
                  when you elect to receive marketing communications from us;
                </li>
              </ol>
              in each case, in accordance with this privacy policy.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Data that is collected automatically
            </h3>
            <li className="mt-4">
              To the extent that you access the Website, we will collect your
              Data automatically, for example:
              <ol className="list-alpha list-inside ml-4">
                <li className="mt-4">
                  we automatically collect some information about your visit to
                  the Website. This information helps us to make improvements to
                  Website content and navigation, and includes your IP address,
                  the date, times and frequency with which you access the
                  Website and the way you use and interact with its content.
                </li>
                <li className="mt-4">
                  we will collect your Data automatically via cookies, in line
                  with the cookie settings on your browser. For more information
                  about cookies, and how we use them on the Website, see the
                  section below, headed &quot;Cookies&quot;.
                </li>
              </ol>
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Our use of Data
            </h3>
            <li className="mt-4">
              Any or all of the above Data may be required by us from time to
              time in order to provide you with the best possible service and
              experience when using our Website. Specifically, Data may be used
              by us for the following reasons:
              <ol className="list-alpha list-inside ml-4">
                <li className="mt-4">
                  improvement of our products / services;
                </li>
                <li className="mt-4">
                  transmission by email of marketing materials that may be of
                  interest to you;
                </li>
              </ol>
              in each case, in accordance with this privacy policy.
            </li>
            <li className="mt-4">
              We may use your Data for the above purposes if we deem it
              necessary to do so for our legitimate interests. If you are not
              satisfied with this, you have the right to object in certain
              circumstances (see the section headed &quot;Your rights&quot;
              below).
            </li>
            <li className="mt-4">
              For the delivery of direct marketing to you via e-mail, we&apos;ll
              need your consent, whether via an opt-in or soft-opt-in:
              <ol className="list-alpha list-inside ml-4">
                <li className="mt-4">
                  soft opt-in consent is a specific type of consent which
                  applies when you have previously engaged with us (for example,
                  you contact us to ask us for more details about a particular
                  product/service, and we are marketing similar
                  products/services). Under &quot;soft opt-in&quot; consent, we
                  will take your consent as given unless you opt-out.
                </li>
                <li className="mt-4">
                  for other types of e-marketing, we are required to obtain your
                  explicit consent; that is, you need to take positive and
                  affirmative action when consenting by, for example, checking a
                  tick box that we&apos;ll provide.
                </li>
                <li className="mt-4">
                  if you are not satisfied about our approach to marketing, you
                  have the right to withdraw consent at any time. To find out
                  how to withdraw your consent, see the section headed
                  &quot;Your rights&quot; below.
                </li>
              </ol>
            </li>
            <li className="mt-4">
              When you register with us and set up an account to receive our
              services, the legal basis for this processing is the performance
              of a contract between you and us and/or taking steps, at your
              request, to enter into such a contract.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Who we share Data with
            </h3>
            <li className="mt-4">
              We may share your Data with the following groups of people for the
              following reasons:
              <ol className="list-alpha list-inside ml-4">
                <li className="mt-4">
                  any of our group companies or affiliates - ;
                </li>
              </ol>
              in each case, in accordance with this privacy policy.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Keeping Data secure
            </h3>
            <li className="mt-4">
              We will use technical and organisational measures to safeguard
              your Data, for example:
              <ol className="list-alpha list-inside ml-4">
                <li className="mt-4">
                  access to your account is controlled by a password and a user
                  name that is unique to you.
                </li>
                <li className="mt-4">we store your Data on secure servers.</li>
              </ol>
            </li>
            <li className="mt-4">
              Technical and organisational measures include measures to deal
              with any suspected data breach. If you suspect any misuse or loss
              or unauthorised access to your Data, please let us know
              immediately by contacting us via this e-mail address:{' '}
              <a href="mailto:privacy@legaleye.com">privacy@legaleye.com</a>
            </li>
            <li className="mt-4">
              If you want detailed information from Get Safe Online on how to
              protect your information and your computers and devices against
              fraud, identity theft, viruses and many other online problems,
              please visit www.getsafeonline.org. Get Safe Online is supported
              by HM Government and leading businesses.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Data retention
            </h3>
            <li className="mt-4">
              Unless a longer retention period is required or permitted by law,
              we will only hold your Data on our systems for the period
              necessary to fulfil the purposes outlined in this privacy policy
              or until you request that the Data be deleted.
            </li>
            <li className="mt-4">
              Even if we delete your Data, it may persist on backup or archival
              media for legal, tax or regulatory purposes.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Your rights
            </h3>
            <li className="mt-4">
              You have the following rights in relation to your Data:
              <ol className="list-alpha list-inside ml-4">
                <li className="mt-4">
                  <b>Right to access</b> - the right to request (i) copies of
                  the information we hold about you at any time, or (ii) that we
                  modify, update or delete such information. If we provide you
                  with access to the information we hold about you, we will not
                  charge you for this, unless your request is &quot;manifestly
                  unfounded or excessive.&quot; Where we are legally permitted
                  to do so, we may refuse your request. If we refuse your
                  request, we will tell you the reasons why.
                </li>
                <li className="mt-4">
                  <b>Right to correct</b> - the right to have your Data
                  rectified if it is inaccurate or incomplete.
                </li>
                <li className="mt-4">
                  <b>Right to erase</b> - the right to request that we delete or
                  remove your Data from our systems.
                </li>
                <li className="mt-4">
                  <b>Right to restrict our use of your Data</b> - the right to
                  &quot;block&quot; us from using your Data or limit the way in
                  which we can use it.
                </li>
                <li className="mt-4">
                  <b>Right to data portability</b> - the right to request that
                  we move, copy or transfer your Data.
                </li>
                <li className="mt-4">
                  <b>Right to object</b>- the right to object to our use of your
                  Data including where we use it for our legitimate interests.
                </li>
              </ol>
            </li>
            <li className="mt-4">
              To make enquiries, exercise any of your rights set out above, or
              withdraw your consent to the processing of your Data (where
              consent is our legal basis for processing your Data), please
              contact us via this e-mail address: privacy@legaleye.com
            </li>
            <li className="mt-4">
              If you are not satisfied with the way a complaint you make in
              relation to your Data is handled by us, you may be able to refer
              your complaint to the relevant data protection authority. For the
              UK, this is the Information Commissioner&apos;s Office (ICO). The
              ICO&apos;s contact details can be found on their website at
              https://ico.org.uk/.
            </li>
            <li className="mt-4">
              It is important that the Data we hold about you is accurate and
              current. Please keep us informed if your Data changes during the
              period for which we hold it.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Transfers outside the European Economic Area
            </h3>
            <li className="mt-4">
              Data which we collect from you may be stored and processed in and
              transferred to countries outside of the United Kingdom (UK) and/or
              the European Economic Area (EEA). For example, this could occur if
              our servers are located in a country outside the UK and/or EEA or
              one of our service providers is situated in a country outside the
              UK and/or EEA. We also share information with our group companies,
              some of which are located outside the UK and/or EEA.
            </li>
            <li className="mt-4">
              We will only transfer Data outside the UK and EEA where it is
              compliant with data protection legislation and the means of
              transfer provides adequate safeguards in relation to your data, eg
              by way of data transfer agreement, incorporating the current
              standard contractual clauses adopted by the European Commission.
            </li>
            <li className="mt-4">
              To ensure that your Data receives an adequate level of protection,
              we have put in place appropriate safeguards and procedures with
              the third partieswe share your Data with. This ensures your Data
              is treated by those third parties in a way that is consistent with
              the Data Protection Laws.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Links to other websites
            </h3>
            <li className="mt-4">
              This Website may, from time to time, provide links to other
              websites. We have no control over such websites and are not
              responsible for the content of these websites. This privacy policy
              does not extend to your use of such websites. You are advised to
              read the privacy policy or statement of other websites prior to
              using them.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Changes of business ownership and control
            </h3>
            <li className="mt-4">
              LegalEye may, from time to time, expand or reduce our business and
              this may involve the sale and/or the transfer of control of all or
              part of LegalEye. Data provided by Users will, where it is
              relevant to any part of our business so transferred, be
              transferred along with that part and the new owner or newly
              controlling party will, under the terms of this privacy policy, be
              permitted to use the Data for the purposes for which it was
              originally supplied to us.
            </li>
            <li className="mt-4">
              We may also disclose Data to a prospective purchaser of our
              business or any part of it.
            </li>
            <li className="mt-4">
              In the above instances, we will take steps with the aim of
              ensuring your privacy is protected.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Cookies
            </h3>
            <li className="mt-4">
              This Website may place and access certain Cookies on your
              computer. LegalEye has carefully chosen these Cookies and has
              taken steps to ensure that your privacy is protected and respected
              at all times.
            </li>
            <li className="mt-4">
              All Cookies used by this Website are used in accordance with
              current UK and EU Cookie Law.
            </li>
            <li className="mt-4">
              Before the Website places Cookies on your computer, you will be
              presented with a message bar requesting your consent to set those
              Cookies. By giving your consent to the placing of Cookies, you are
              enabling LegalEye to provide a better experience and service to
              you. You may, if you wish, deny consent to the placing of Cookies;
              however certain features of the Website may not function fully or
              as intended.
            </li>
            <li className="mt-4">
              This Website may place the following Cookies:
              <table>
                <tr className="border-b">
                  <th className="p-2 pl-0">Type of Cookie</th>
                  <th className="p-2 pr-0">Purpose</th>
                </tr>
                <tr className="border-b">
                  <td className="p-2 pl-0">Analytical/performance cookies</td>
                  <td className="p-2 pr-0">
                    They allow us to recognise and count the number of visitors
                    and to see how visitors move around our website when they
                    are using it. This helps us to improve the way our website
                    works, for example, by ensuring that users are finding what
                    they are looking for easily
                  </td>
                </tr>
              </table>
            </li>
            <li className="mt-4">
              You can find a list of Cookies that we use in the Cookies
              Schedule.
            </li>
            <li className="mt-4">
              You can choose to enable or disable Cookies in your internet
              browser. By default, most internet browsers accept Cookies but
              this can be changed. For further details, please consult the help
              menu in your internet browser.
            </li>
            <li className="mt-4">
              You can choose to delete Cookies at any time; however you may lose
              any information that enables you to access the Website more
              quickly and efficiently including, but not limited to,
              personalisation settings.
            </li>
            <li className="mt-4">
              It is recommended that you ensure that your internet browser is
              up-to-date and that you consult the help and guidance provided by
              the developer of your internet browser if you are unsure about
              adjusting your privacy settings.
            </li>
            <li className="mt-4">
              For more information generally on cookies, including how to
              disable them, please refer to aboutcookies.org. You will also find
              details on how to delete cookies from your computer.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              General
            </h3>
            <li className="mt-4">
              You may not transfer any of your rights under this privacy policy
              to any other person. We may transfer our rights under this privacy
              policy where we reasonably believe your rights will not be
              affected.
            </li>
            <li className="mt-4">
              If any court or competent authority finds that any provision of
              this privacy policy (or part of any provision) is invalid, illegal
              or unenforceable, that provision or part-provision will, to the
              extent required, be deemed to be deleted, and the validity and
              enforceability of the other provisions of this privacy policy will
              not be affected.
            </li>
            <li className="mt-4">
              Unless otherwise agreed, no delay, act or omission by a party in
              exercising any right or remedy will be deemed a waiver of that, or
              any other, right or remedy.
            </li>
            <li className="mt-4">
              This Agreement will be governed by and interpreted according to
              the law of England and Wales. All disputes arising under the
              Agreement will be subject to the exclusive jurisdiction of the
              English and Welsh courts.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Changes to this privacy policy
            </h3>
            <li className="mt-4">
              LegalEye reserves the right to change this privacy policy as we
              may deem necessary from time to time or as may be required by law.
              Any changes will be immediately posted on the Website and you are
              deemed to have accepted the terms of the privacy policy on your
              first use of the Website following the alterations. You may
              contact LegalEye by email at privacy@legaleye.com.
            </li>
            <h3 className="text-xl mt-8 font-GTWalsheimPro font-medium">
              Cookies
            </h3>
            <p>
              Below is a list of the cookies that we use. We have tried to
              ensure this is complete and up to date, but if you think that we
              have missed a cookie or there is any discrepancy, please let us
              know.
            </p>
            <div>Analytical/performance</div>
            <div>We use the following analytical/performance cookies:</div>
            <table>
              <tr className="border-b">
                <th className="p-2 pl-0">Description of Cookie</th>
                <th className="p-2 pr-0">Purpose</th>
              </tr>
              <tr className="border-b">
                <td className="p-2 pl-0">Google Analytics cookies</td>
                <td className="p-2 pr-0">
                  Google Analytics helps us analyze how the site is used. Google
                  Analytics generate statistical and other information about
                  website use by means of cookies, which are stored by your
                  computer&apos;s browser. The information generated relating to
                  our website is used to create reports about the use of the
                  website. Google holds this information and provides us access
                  to it.
                </td>
              </tr>
            </table>
          </ol>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
